import React from 'react';

import QuestionCard from './question';
import ExplainAgent from './explainAgent';
import ExplainCompany from './explainCompany';

import './style.css';

const HowItWorks: React.FC = () => {
    const [user, setUser] = React.useState<string>('');

    return (
        <div id='howItWorks' className='min-h-screen flex flex-col mt-20 px-[8vw] gap-20'>
            {user === '' ? <QuestionCard setUser={setUser}/> :
                <>
                    <div className='flex justify-center'>
                        <div id='toogle-box' className="relative card flex justify-center items-center w-[20rem] h-[3rem]">
                            <div className={`absolute w-[49%] h-[80%] rounded-full bg-blue transition-all duration-300 ${user === "company" ? " translate-x-[49%]" : " translate-x-[-49%]"}`}></div>
                            <p onClick={() => setUser('agent')} className={`relative w-1/2 cursor-pointer ${user === 'company' ? 'text-[var(--soft-text)]' : 'text-white'}`}>agent</p>
                            <p onClick={() => setUser('company')} className={`relative w-1/2 cursor-pointer ${user === 'agent' ? 'text-[var(--soft-text)]' : 'text-white'}`}>company</p>
                        </div>
                    </div>
                    <div className='text-left'>
                        <h3 className='text-[2rem] font-bold text-yellow'>How it works</h3>
                        <p className='howItWorks-p text-[1.2rem] mt-2 w-1/2'>Upon gaining entry to the Worklab platform, understand how the platform works and its benefits.</p>
                    </div>
                    {user === 'agent' ? <ExplainAgent/> : <ExplainCompany/>}
                </>
                
            }
        </div>
    );
};

export default HowItWorks;
