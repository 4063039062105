import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Website, About, Terms, Support, Policy, NotFoundPage} from './pages/index';

function App() {

  const routes = [
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/terms-and-conditions",
      element: <Terms />,
    },
    {
      path: "/support",
      element: <Support />,
    },
    {
      path: "/privacy-policy",
      element: <Policy />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    }
  ]

  return (
    <div id="app" className='relative flex flex-col text-center font-GilroyLight min-h-screen'>
      <BrowserRouter>
        <Routes>
          <Route index element={<Website />} />
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path} 
                element={route.element}>
              </Route>
            )
          })}
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
