import Logo from '../../../content/images/logos/logo.png';

export default function Navbar() {

    function openMenu() {
        document.getElementById('website-navbar')?.classList.toggle('open');
        document.getElementById('navbar-btn')?.classList.toggle('open');
    }

    function MenuBtn() {
        return (
            <div onClick={() => openMenu()} id="navbar-btn" className="relative w-[20px] h-[18px] hidden flex-col justify-between">
                <div className="relative w-full h-[3px] bg-white"></div>
                <div className="relative w-full h-[3px] bg-white"></div>
                <div className="relative w-full h-[3px] bg-white"></div>
            </div>
        );  
    }

    // function checkNavbar() {
    //     let navbar = document.getElementById('website-navbar');
    //     if (window.scrollY !== 0) {
    //         if (navbar)
    //             navbar.style.boxShadow = "0 0 10px rgba(0,0,0,.5)";
    //     } else {
    //         if (navbar)
    //             navbar.style.boxShadow = "none";
    //     }
    // }

    // window.addEventListener('scroll', checkNavbar);

    return (
        <div id="website-navbar" className='absolute top-0 left-0 w-full flex justify-between items-center px-[8vw] py-5 z-[10] transition-all'>
            <div className='flex items-center'>
                <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} src={Logo} alt="img" className='cursor-pointer w-40'/>
            </div>
            <div id="website-navbar-links" className='flex justify-end gap-[8vw] text-[1.2rem] font-bold items-center'>
                {/* <div id='website-navbar-links-extras' className='hidden gap-[8vw]'>
                    <a href="https://worklabspace.com/about" className='navbar-link'>ABOUT</a>
                    <a href="https://startups.visiond.pt" className='navbar-link'>LAUNCH</a>
                </div> */}
                <a href="https://app.worklabspace.com" className='navbar-link'>LOGIN</a>
                <a href="https://app.worklabspace.com/signup" className='navbar-link'>SIGN UP</a>
            </div>
            <MenuBtn />
        </div>
    );
}