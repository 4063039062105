import React, { useEffect, useState } from 'react';

import './footer.css';

export default function Footer() {
    const [paddingLeft, setPaddingLeft] = useState("pl-[11rem]");

    function checkPaths() {
        if (window.location.pathname === '/' 
            || window.location.pathname === '/login' 
            || window.location.pathname === '/signup'
            || window.location.pathname === '/about'
            || window.location.pathname === '/terms-and-conditions'
            || window.location.pathname === '/support'
            || window.location.pathname === '/privacy-policy')
            return true;
        return false;
    }

    useEffect(() => {
        if (checkPaths()) {
            setPaddingLeft("");
        } else {
            setPaddingLeft("pl-[11rem]");
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (checkPaths()) {
                setPaddingLeft("");
                return ;
            }
            if (!checkPaths() || (window.innerWidth >= 700 && window.innerWidth <= 800))
                setPaddingLeft("pl-[11rem]");
            if (checkPaths() && window.innerWidth <= 900)
                setPaddingLeft("pl-[2rem]");
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <footer id="footer" className={`relative flex items-center justify-between w-full pt-3 pb-6 px-[5vw] ${paddingLeft} gap-8 text-[var(--soft-text)]`}>
            <div className={`relative flex w-[75%] gap-10 items-center`}>
                <a href="/about" className='hover:underline'>About</a>
                <a href="/terms-and-conditions" className='hover:underline'>Terms of Use</a>
                <a href="/privacy-policy" className='hover:underline'>Privacy Policy</a>
                <a href="/support" className='hover:underline'>Support</a>
            </div>
            <div className='relative flex justify-end w-[25%] items-center'>
                <p>Powered by <a href="https://visiond.pt" className='font-bold text-[var(--text)] hover:underline'>Vision D</a></p>
            </div>
        </footer>
    );
}
