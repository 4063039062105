import React, { useState } from 'react';

import './support.css';
import Footer from '../../components/Footer/footer';

export default function About() {

    const [subject, setSubject] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    function isValidEmail(email: string): boolean {
        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailPattern.test(email);
    }

    function sendRequest() {
        let error = document.getElementById('support-error-msg') as HTMLParagraphElement;
        error.style.color = 'red';
        if (subject === "") {
            error.innerText = "Subject requiered";
            return ;
        }
        if (email === "" || !isValidEmail(email)) {
            error.innerText = "Write a valid email";
            return ;
        }
        if (message === "") {
            error.innerText = "Message requiered";
            return ;
        }
        
        // TODO send email
        setSubject("");
        setEmail("");
        setMessage("");
        error.style.color = 'var(--green)';
        error.innerText = "Message sent succesfully!\nWe will back at you soon.";
        setTimeout(() => error.innerText = "", 4000);
    }

    return (
        <>
            <p onClick={() => window.history.back()} className='absolute top-8 left-8 cursor-pointer z-[5]'><span className='text-yellow'>←</span> &ensp; <span>Go back</span></p>
            <div id='support' className='relative w-full min-h-screen flex flex-col px-[1rem] py-[5rem] pt-[7rem] justify-center items-center'>
                <h1 className='text-h2 mb-14'>Worklab support</h1>
                <div id='support-form' className='relative w-[20rem] flex flex-col gap-4'>
                    <input value={subject} 
                        onChange={(e) => setSubject(e.target.value)} 
                        type="text" 
                        name='subject' 
                        placeholder='| Subject' />
                    <input value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        type="email" 
                        name='email' 
                        placeholder='| Email' />
                    <textarea value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        name="message" 
                        id="support-form-message" 
                        cols={30} rows={10} 
                        placeholder='| Describe your issue here'></textarea>
                    <p id='support-error-msg' className='text-red text-left'></p>
                    <div className='flex justify-start'>
                        <button id='support-btn' onClick={sendRequest} className='bg-blue rounded-full px-6 py-1'>Send</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
