
import Logo from '../../../content/images/logos/logo-blue.png';

export default function WebsiteFooter() {
    return (
        <footer id='website-footer' className='min-h-[50vh] px-[8vw] mt-20 text-left'>
            <div className='flex justify-between'>
                <div className='invert-card w-[25rem] h-[15rem] flex flex-col justify-between p-[2rem]'>
                    <div>
                        <h2 className='text-[1.5rem]'>Subscribe</h2>
                        <p className='text-[var(--soft-text)] mt-2'>Stay current with all things about Worklab</p>
                    </div>
                    <div className='flex items-center w-full h-[3.5rem] border-2 pl-4 pr-2 border-[var(--soft-text)] rounded-full'>
                        <input type='text' className='w-1/2 h-[2rem] bg-[transparent] outline-none text-white p-[5px]' placeholder='| Email' />
                        <button className='w-1/2 h-[2.5rem] bg-white text-black rounded-full hover:bg-[transparent] hover:text-white'>Subscribe</button>
                    </div>
                </div>
                <div id="website-footer-content" className='flex gap-14'>
                    <div className='flex flex-col gap-4'>
                        <h3 className='text-[1.2rem]'>RESOURCES</h3>
                        <ul className='flex flex-col gap-4 text-[var(--soft-text)]'>
                            <li className='cursor-pointer hover:underline'><a href="/about">About</a></li>
                            <li className='cursor-pointer hover:underline'><a href="/privacy-policy">Privacy Policy</a></li>
                            <li className='cursor-pointer hover:underline'><a href="/terms-and-conditions">Terms of Use</a></li>
                        </ul>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h3 className='text-[1.2rem]'>SEE MORE</h3>
                        <ul className='flex flex-col gap-4 text-[var(--soft-text)]'>
                            <li className='cursor-pointer hover:underline'><a href="https://visiond.pt">Vision D</a></li>
                            <li className='cursor-pointer hover:underline'><a href="https://app.worklabspace.com">Worklab</a></li>
                            <li className='cursor-pointer hover:underline'><a href="https://startups.visiond.pt">Startup launch</a></li>
                        </ul>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h3 className='text-[1.2rem]'>CONTACTS</h3>
                        <ul className='flex flex-col gap-4 text-[var(--soft-text)]'>
                            <li className='cursor-pointer hover:underline'><a href="/support">Support</a></li>
                            <li className='cursor-pointer hover:underline'><a href="mailto:geral@visiond.pt">General</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="credits" className="flex justify-between mt-20 items-center mb-10">
                <p  className="text-[var(--soft-text)]">© 2023 Worklab. All Rights Reserved.</p>
                <img src={Logo} alt="logo" className="relative w-[13rem] bottom-5 cursor-pointer" onClick={() => window.scrollTo(0,0)} />
            </div>
        </footer>
    );
}