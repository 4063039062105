import React from 'react';

import ChartImg from '../../../../content/images/website/chart2.png';
import { Project, VacancyCard } from './utils'

const Company: React.FC = () => {

    const [section, setSection] = React.useState<number>(1);
	const [type, setType] = React.useState("Vacancy");

    const Sections = ({ section }: { section: number }) => {

        const sectionCompany = [
            <>
                <div className='invert-card w-full min-h-full p-8 flex flex-col gap-6'>
                    <h3 className='text-yellow'>Create Project</h3>
                    <div className="edit-project-input relative flex flex-col">
                        <h4 className="text-h5 text-left w-full mb-2">Project name:</h4>
                        <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-[80%] rounded-full border-2 border-blue text-p text-white" placeholder="|"/>
                    </div>
                    <div className="edit-project-input relative flex flex-col">
                        <h4 className="text-h5 text-left w-full mb-2 ">Project type:</h4>
                        <div className="create-project-section relative flex gap-4">
                            <div className="relative flex items-center gap-4">
                                <p>Vacancy</p>
                                <input id="edit-project-type-vacancy" checked={type === "Vacancy"} type="checkbox" className="filters-type rounded-full bg-[#ddd] cursor-pointer" onChange={() => setType("Vacancy")} />
                            </div>
                            <div className="relative flex items-center  gap-4">
                                <p>Training</p>
                                <input id="edit-project-type-training" checked={type === "Training"} type="checkbox" className="filters-type rounded-full bg-[#ddd] cursor-pointer" onChange={() => setType("Training")} />
                            </div>
                        </div>
                    </div>
                    <div className="edit-project-input relative flex flex-col">
                        <h4 className="text-h5 text-left w-full mb-2">Subject:</h4>
                        <div className="create-project-section  flex gap-4">
                            <button
                                id="upload-file-fake-input"
                                className="relative bg-blue  w-[7.5rem] py-[3px]  rounded-full text-white"
                            >
                                upload
                            </button>
                            <input
                                id="upload-file-input"
                                className="hidden"
                                type="file"
                                placeholder="upload"
                            />
                            <button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">AI generate</button>
                        </div>
                    </div>
                </div>
                <div className=' w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>01</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Register & Set Goals</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Create a company profile on Worklab, detailing your industry, values, and objectives.</li>
                        <li className='list-disc'>Decide if you want to offer a Vacancy project for recruitment or a Training project to nurture talent.</li>
                    </ul>
                </div>
            </>,
            <>
                <div className={`w-full min-h-full flex flex-col justify-center items-center  gap-6`}>
                    <Project role={"Software Engineer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Java"} method={"Remote"} deadline={"25-11-2023"} />
                    <Project role={"Data Analyst"} type={"Training"} contract={"Full-time"} company={"Vision D"} tech={"Python"} method={"On-site"} deadline={"15-01-2024"} />
                </div>
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>02</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Design & Publish</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Design your project, outlining clear requisites and expectations.</li>
                        <li className="list-disc">Submit or generate with AI the project's subject.</li>
                        <li className='list-disc'>Publish the project, making it accessible to a community of talented agents ready to take on challenges.</li>
                    </ul>
                </div>
            </>,
            <>
                <div className='hiw-section-three invert-card w-full min-h-full p-8 flex flex-col '>
                    <h3 className='mb-8'>Vacancies opened</h3>
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                        <VacancyCard role="Software Engineer" vacanciesOpened='46' />
                        <VacancyCard role="UX/UI Designer" vacanciesOpened='23' />
                        <VacancyCard role="Data Analyst" vacanciesOpened='28' />
                    </div>
                </div>
                <div className=' w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>03</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Search & Select</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Once the deadline arrives, view the submissions.</li>
                        <li className='list-disc'>Collaborate with the Worklab team to grade projects and identify top-performing agents suitable for your needs.</li>
                        <li className='list-disc'>Unlock immediate access to a talent pool with Worklab Premium. Find the right fit without posting a new vacancy.</li>
                    </ul>
                </div>
            </>,
            <>
                <div className='invert-card w-full min-h-full p-6 justify-center items-center flex flex-col gap-6'>
                    <h2 className='font-bold tracking-[1px]'>Company stats</h2>
                    <img src={ChartImg} alt="chart"  />
                </div>
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>04</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Engage & Partner</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>For Vacancy projects, onboard the best agents to join your company.</li>
                        <li className='list-disc'>Offer courses and training modules to the Worklab community, positioning your company as an industry leader and fostering continuous learning.</li>
                    </ul>
                </div>
            </>
        ];

        return sectionCompany[section - 1];
    }

    return (
        <div className='w-full h-full text-left'>
            <div className='howItWorks-card card flex w-full min-h-[25rem] p-8'>
                <Sections section={section} />
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mt-8">
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 1 ? "border-green" : "border-grey"}`} onClick={() => setSection(1)}>
                    <p className='text-green'>01</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Register & Set Goals</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 2 ? "border-green" : "border-grey"}`} onClick={() => setSection(2)}>
                    <p className='text-green'>02</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Design & Publish</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 3 ? "border-green" : "border-grey"}`} onClick={() => setSection(3)}>
                    <p className='text-green'>03</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Search & Select</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 4 ? "border-green" : "border-grey"}`} onClick={() => setSection(4)}>
                    <p className='text-green'>04</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Engage & Partner</p>
                </div>
            </div>

        </div>
    );
};

export default Company;