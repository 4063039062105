import CourseImg from "../../../../content/images/courses/courses-example8.png";
import VacancyImg from "../../../../content/images/icons/icon-paper.png";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function ProjectCard({ ...props }) {
    return (
        <div id="project-card" className="relative invert-card w-full p-4 gap-4 flex justify-between text-[.9rem] border-2 border-[var(--card-one)] hover:border-2 hover:border-yellow">
            <div className="w-full h-full text-left flex flex-col justify-between gap-4">
                <div>
                    <p id='project-card-role' className="text-white text-[1.2rem]">{props.role}</p>
                    <p className="text-[var(--soft-text)]">{props.company}</p>
                </div>
                <div id="project-card-info" className="flex gap-8">
                    {props.type === "Vacancy" ?
                        <div id='project-card-vacancy-info'>
                            <p className="text-[var(--soft-text)]">{props.contract}</p>
                            <p className="text-[var(--soft-text)]">{props.method}</p>
                        </div>
                        : null}
                    <div>
                        <p className="text-[var(--soft-text)]">Tech: {props.tech}</p>
                        <p className="text-[var(--soft-text)]">Deadline: {props.deadline}</p>
                    </div>
                </div>
            </div>
            <div id="project-card-button" className="w-[30%] h-full text-right flex flex-col items-end justify-end">
                <button className="w-[5rem] h-[2rem] text-center bg-white rounded-full text-black transition-all duration-300 hover:bg-yellow">apply</button>
            </div>
            <p id='project-card-type' className={`${props.type === "Vacancy" ? "text-green" : "text-blue"} absolute top-4 right-4`}>{window.innerWidth <= 400 ? props.type[0] : props.type}</p>
        </div>
    )
}

function CoursesCard({ ...props }) {
	return (
		<div className="courses-card w-[90%] h-[90%] invert-card relative flex flex-col cursor-pointer pb-1">
			<div className="courses-card-top relative flex h-3/5">
				<img src={CourseImg} alt="img" className="relative w-full" />
			</div>
			<div className="courses-card-bottom relative flex flex-col justify-between h-2/5 px-5 py-2">
				<p className="sm:text-[1rem] text-left mb-6 text-[.8rem]">{props.about}</p>
				<div className="relative flex justify-between items-center ">
					<h5 className="text-h5"><b>{props.company}</b></h5>
					<div className="relative flex flex-col">
						<h5 className="text-h5 text-right"><b>{props.participants}</b> px.</h5>
					</div>
				</div>
			</div>
		</div>
	);
}

function EvalItem({ ...props }) {
	let active = props.deadline <= 1 ? "close-deadline" : "";
	return (
		<div className={"group eval-item relative invert-card flex justify-between items-center w-full h-[5rem] text-left px-4 rounded-[18px] mb-5 cursor-pointer " + active}>
			<div className="relative flex flex-col">
				<h3 className="text-h4">{props.name}</h3>
				<h4 className="text-h5">from {props.user}</h4>
			</div>
			{props.deadline <= 1 ? <h2 className='relative text-yellow'><span>1</span> day left</h2> : null}
			<div id="eval-item-arrow" className="eval-item-arrow relative flex flex-col justify-between items-center pr-4 transition-all group-hover:pr-0">
				<p className="group-hover:text-yellow">→</p>
			</div>
		</div>
	);
}

function ItSkill({ skill, knowledge }: { skill: string, knowledge: string }) {
	return (
		<div className="relative flex justify-center items-center w-full mb-6">
			<h5 className="text-h5 mr-5  w-1/3">{skill}</h5>
			<div id="skills-card-progress-bg" className="relative w-1/2 bg-[var(--stats-bg)] h-4 rounded-full">
				<div style={{ width: `${knowledge}%` }} className={`absolute h-4 rounded-full it-skill-line bg-[var(--stats)]`}></div>
			</div>
		</div>
	);
}

function Vacancy({role, vacanciesOpened} : {role:string, vacanciesOpened:string}) {
    return (
      <div className="profile-vacancy relative flex justify-between items-center w-full mb-2 pb-5">
        <div className="relative flex justify-start items-center w-[40%]">
          <img src={VacancyImg} alt="img" className="w-10 invert mr-5"/>
          <h2 className="mr-10">{role}</h2>
        </div>
        <h3 id='vacancy-card-number' className="text-[var(--yellow)]"># {vacanciesOpened}</h3>
        <button className="mr-5 bg-blue rounded-full px-5 text-white">view</button>
      </div>
    );
}


const ProjectChart = () => {
    const getIntroOfPage = (label:string) => {
        if (label === 'Total') {
          return ["Number of vacancies in Worklab", "Number of projects in Worklab"];
        }
        if (label === 'Average') {
          return ["Average vacancies in Worklab", "Average projects in Worklab"];
        }
        if (label === 'Mine') {
          return ["Number of vacancies I opened", "Number of projects I created"];
        }
        return '';
      };
      
      const CustomTooltip = ({ ...props }) => {
        if (props.active && props.payload && props.payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label"><span className="intro">{getIntroOfPage(props.label)[0]}</span> : {`${props.payload[0].value}`}</p>
              <p className="label"><span className="intro">{getIntroOfPage(props.label)[1]}</span> : {`${props.payload[1].value}`}</p>
            </div>
          );
        }
      
        return null;
      };
  
      let totalProjects = 204;
      let averageProjects = 27;
      let companyTotalProjects = 55
  
      let totalVacancies = 548;
      let averageVacancies = 302;
      let companyTotalVacancies = 83
  
      let data = [
          {
          name: 'Total',
          uv: 4000,
          amt: 2400,
          Vacancies: totalVacancies,
          Projects: totalProjects,
          },
          {
          name: 'Average',
          uv: 3000,
          amt: 2210,
          Vacancies: averageVacancies,
          Projects: averageProjects,
          },
          {
          name: 'Mine',
          uv: 2000,
          amt: 2290,
          Vacancies: companyTotalVacancies,
          Projects: companyTotalProjects,
          }
      ]
      return (
          <ResponsiveContainer width="100%" height="100%">
          <BarChart
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 40,
              bottom: 20,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="Vacancies" barSize={25} fill="#f7c940" />
            <Bar dataKey="Projects" barSize={25} fill="#1B75F8" />
          </BarChart>
        </ResponsiveContainer>
      );
  };
  

export const Project = ProjectCard;
export const Course = CoursesCard;
export const Eval = EvalItem;
export const Skill = ItSkill;
export const VacancyCard = Vacancy;
export const Chart = ProjectChart;