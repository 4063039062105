import React from 'react';
import './website.css';

import Navbar from './components/navbar';
import Header from './components/header';
import About from './components/about';
import HowItWorks from './components/howItWorks/howItWorks';
import Jobs from './components/jobs';
import Cards from './components/cards';
import WebsiteFooter from './components/footer';

export default function Website() {

    return (
        <>
            <Navbar/>
            {/* <div className="circle absolute top-[-5rem] left-[-5rem] w-[20rem] h-[20rem] z-[1]">
                <div className="inner-circle yellow"></div>
            </div>
            <div className="triangle absolute top-[15rem] right-[-15rem] w-[22rem] h-[20rem] z-[1]">
                <div className="inner-triangle purple"></div>
            </div> */}
            <div id='website' className='relative flex flex-col pt-8'>
                <div id='website-bg-one'>
                    <Header/>
                    <About/>
                </div>
                <div id='website-bg-two'>
                    <HowItWorks/>
                    <Jobs/>
                </div>
                <div id='website-bg-three'>
                    <Cards/>
                    <WebsiteFooter/>
                </div>
            </div>
        </>
    );
}