import React from 'react';

import './policy.css';
import PolicyData from './data.json';
import Footer from '../../components/Footer/footer';

export default function Policy() {
    return (
        <>
            <div id="Policy" className='relative w-full flex flex-col px-[15rem] text-left py-[5rem]'>
                <p onClick={() => window.history.back()} className='absolute w-full top-0 left-0 py-5 px-5 text-left cursor-pointer z-[5]'><span className='text-yellow'>←</span> &ensp; <span>Go back</span></p>
                <h1 className='text-h2'>Worklab <br /> Privacy Policy</h1>
                <p className='text-p pb-16'>Last Updated: 01/10</p>
                <div className='relative flex flex-col gap-10'>
                    {
                        PolicyData.data.map((section) => {
                            return (
                                <div key={section.id} className='relative w-full flex flex-col gap-5'>
                                    <h2 className='text-[var(--yellow)] text-h3'>
                                        {section.title}
                                    </h2>
                                    <p>
                                        {section.text}
                                    </p>
                                </div>
                            )
                        })
                    }
                    <div className='relative w-full flex flex-col gap-5'>
                        <h2 className='text-[var(--yellow)] text-h3'>
                            Contact us
                        </h2>
                        <p>
                            If you have any questions or concerns about this Privacy Policy, please contact us <a href="/support" className='font-bold hover:underline'>here</a>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
