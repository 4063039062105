import React from 'react';

export default function Jobs() {
    const jobTitles = [
        "Web Developer",
        "Software Developer",
        "Graphic Designer",
        "Brand Manager",
        "Social Media Manager",
        "Marketing Coordinator",
        "Investment Banking Analyst",
        "Financial Analyst",
        "Financial Manager",
        "Sales Manager",
        "Human Resources Manager",
        "Mechanical Engineer",
        "Content Manager",
        "Data Analyst",
        "Digital Marketing Manager",
        "Operations Manager",
        "Back-end Developer",
        "Senior Accountant",
        "Front-end Developer",
        "Motion Designer",
        "Middle Engineer",
        "Design Team Lead",
        "Senior 3D Designer",
        "UI/UX Designer",
        "Senior Dev Ops",
        "Project Manager"
    ];
    
    
      return (
        <div className="py-20 flex items-center justify-center text-white my-20">
          <div className="flex flex-col space-y-4">
            <div className="job-scroll-left overflow-hidde p-4 ">
              <div className="flex space-x-10">
                {jobTitles.concat(jobTitles).map((title, index) => (
                  <div className='job-title' key={index}>{title}</div>
                ))}
                {jobTitles.concat(jobTitles).map((title, index) => (
                  <div className='job-title' key={index}>{title}</div>
                ))}
              </div>
            </div>
            <div className="job-scroll-right overflow-hidde p-4 ">
              <div className="flex space-x-10">
                {jobTitles.concat(jobTitles).map((title, index) => (
                  <div className='job-title' key={index}>{title}</div>
                ))}
                {jobTitles.concat(jobTitles).map((title, index) => (
                  <div className='job-title' key={index}>{title}</div>
                ))}
              </div>
            </div>
            <div className='pt-10'>
                <button className='w-[8rem] h-[2.5rem] bg-white text-black rounded-full hover:text-white hover:bg-[transparent]' onClick={() => window.location.replace('https://app.worklabspace.com')}>Start Now</button>
            </div>
          </div>
        </div>
    );
}