import React from 'react';

const Cards: React.FC = () => {
    return (
        <div id='website-cards' className='flex justify-center gap-8 px-[8vw]'>
            <div className='card text-left w-full min-h-[25rem] p-20 flex flex-col gap-8 justify-center transition-all border-2 border-[transparent] hover:border-2 hover:border-yellow'>
                <p className='text-[1rem] text-green font-bold'>CAREERS</p>
                <h2 className='text-[2rem]'>Join to help businesses grow</h2>
                <div>
                    <button className='w-[12rem] h-[3rem] rounded-full bg-white text-black hover:bg-[transparent] hover:text-white' onClick={() => window.location.replace('https://app.worklabspace.com')}>Explore Careers&ensp;➜</button>
                </div>
            </div>
            <div className='card text-left w-full min-h-[25rem] p-20 flex flex-col gap-8 justify-center hover:border-2 hover:border-yellow'>
                <p className='text-[1rem] text-green font-bold'>VISION D - STARTUPS</p>
                <h2 className='text-[2rem]'>Let us help you launch your idea</h2>
                <div>
                    <button className='w-[12rem] h-[3rem] rounded-full bg-white text-black hover:bg-[transparent] hover:text-white' onClick={() => window.location.replace('https://startups.visiond.pt')}>Launch here&ensp;➜ </button>
                </div>
            </div>
        </div>
    );
};

export default Cards;
