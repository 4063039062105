import React, { useEffect, useState } from 'react';

export default function Header() {
  const [textIndex, setTextIndex] = useState(0);
  const textArray = [
    'job',
    'talent',
    'career',
    'agent',
    'dream',
    'position',
    'vocation',
    'workforce',
    'profession'
  ];  

  useEffect(() => {
    const changeText = document.getElementById('change-text') as HTMLSpanElement;

    const interval = setInterval(() => {
      changeText.style.opacity = '0';
      setTimeout(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
        changeText.style.opacity = '1'; 
      }, 500); 
    }, 8000);

    return () => clearInterval(interval);
  }, [textArray.length]);

  return (
    <div
      id="website-header"
      className="website-section relative w-full min-h-screen flex items-center justify-center px-[10vw]"
    >
      <div
        id="website-header-container"
        className="relative w-full h-full flex flex-col text-center justify-center items-center"
      >
        <div id='website-header-explore' className='w-[20rem] h-[2.5rem] py-[5px] rounded-full mb-10 flex justify-center items-center border-[1px] border-grey'>
          <p className='pr-[10px]'>The best job seekers</p>
          <p className='group border-l-[1px] pl-[10px] border-grey cursor-pointer text-blue' onClick={() => window.location.replace('https://app.worklabspace.com')}><span className='group-hover:text-white transition-all duration-300'>Explore</span> <span className='transition-all duration-300 group-hover:ml-2'>➜</span></p>
        </div>
        <h1 className="w-[45rem] text-[3rem] font-bold mb-5">
          Finding the{' '}
          <span className="text-[var(--yellow)]">
            perfect <span id="change-text" className='transition-all'>{textArray[textIndex]}</span>
          </span>{' '}
            has never been so easy
        </h1>
        <h2 className="w-[40rem] text-[1rem]">
          Worklab is a place where we put companies and talents together with an educational recruitment methodology.
        </h2>
        <div className='mt-10 border-[1px] border-white w-8 h-8 flex items-center justify-center rounded-full transition-all duration-500 cursor-pointer hover:scale-125'>
            <a href="/#website-about">↓</a>
        </div>
      </div>
    </div>
  );
}
