import React, { useEffect, useRef } from 'react';

type QuestionCardProps = {
    setUser: (value: string) => void;
};

const QuestionCard: React.FC<QuestionCardProps> = ({ setUser }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((ele) => {
                if (ele.isIntersecting) {
                    ele.target.classList.remove('scale-0');
                } else {
                    ele.target.classList.add('scale-0');
                }
            })
        });

        const hiddenElements = document.querySelectorAll(".hide");
        hiddenElements.forEach((ele) => observer.observe(ele));
    }, [])

    function isAboveMiddleOfScreen() {
        if (!ref.current) {
          return false;
        }
        const rect = ref.current.getBoundingClientRect();
        const middleOfScreen = window.innerHeight / 2;
        return rect.top + 100 <= middleOfScreen;
      }
    
      useEffect(() => {
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
        function handleScroll() {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
          if (isAboveMiddleOfScreen() && currentScrollTop > lastScrollTop) {
            // Disable only scroll down
            window.scrollTo(0, lastScrollTop);  // Reset scroll position to previous value
          } else {
            lastScrollTop = currentScrollTop;
          }
        }
    
        // Initial check doesn't require event handling
        if (isAboveMiddleOfScreen()) {
          // Handle the case where the element is initially above the middle of the screen
        }
    
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup listener on unmount
        return () => window.removeEventListener('scroll', handleScroll);
      }, [ref]);


    return (
        <div className='min-h-screen flex justify-center items-center'>
            <div id='question-card' className='hide card flex flex-col justify-center items-center scale-0'
                style={{
                    width: '30rem',
                    height: '20rem',
                    transition: 'transform 3000ms ease',
                }}
                ref={ref}
            >
                <p className='text-[1.2rem]'>Who are you?</p>
                <div className='mt-8 flex gap-4 '>
                    <button className='w-[7rem] h-[2rem] bg-blue rounded-full' onClick={() => setUser("agent")}>Agent</button>
                    <button className='w-[7rem] h-[2rem] bg-blue rounded-full' onClick={() => setUser("company")}>Company</button>
                </div>
            </div>
        </div>
    );
};

export default QuestionCard;
