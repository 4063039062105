import Arrow from '../../../content/images/others/arrow.png';

export default function About() {

	function ProjectCard({ ...props }) {
		return (
			<div className="project-card relative invert-card w-full p-4 gap-4 flex justify-between text-[.9rem] border-2 border-[var(--card-one)] hover:border-2 hover:border-yellow">
				<div className="w-full h-full text-left flex flex-col justify-between gap-4">
					<div>
						<p id='project-card-role' className="text-white text-[1.2rem]">{props.role}</p>
						<p className="text-[var(--soft-text)]">{props.company}</p>
					</div>
					<div className="flex gap-8">
						{props.type === "Vacancy" ?
							<div id='project-card-vacancy-info'>
								<p className="text-[var(--soft-text)]">{props.contract}</p>
								<p className="text-[var(--soft-text)]">{props.method}</p>
							</div>
							: null}
						<div>
							<p className="text-[var(--soft-text)]">Tech: {props.tech}</p>
							<p className="text-[var(--soft-text)]">Deadline: {props.deadline}</p>
						</div>
					</div>
				</div>
				<div className="w-[30%] h-full text-right flex flex-col items-end justify-between">
					<p id='project-card-type' className={`${props.type === "Vacancy" ? "text-green" : "text-blue"} mt-1`}>{props.type}</p>
					<button className="w-[5rem] h-[2rem] text-center bg-white rounded-full text-black transition-all duration-300 hover:bg-yellow">apply</button>
				</div>
			</div>
		)
	}


	return (
		<div id="website-about" className="website-section relative w-full flex py-20 px-[8vw]">
			<div id="website-about-left" className="relative w-full h-full flex flex-col text-left justify-center">
				<h1 className="w-full text-[2rem] font-bold mb-5">About Worklab</h1>
				<h2 className="text-[1rem] w-[60%]">We merge skill-building with job opportunities, ensuring learners grow while companies find top talent.</h2>
				<div className='flex items-center my-10'>
					<img src={Arrow} alt="arrow icon" className='h-5 invert ' />
					<h3 className='text-[1.5rem] text-blue font-extrabold pl-4'>EXECUTIVE EDUCATION</h3>
				</div>
				<div className='flex items-center'>
					<img src={Arrow} alt="arrow icon" className='h-5 invert ' />
					<h3 className='text-[1.5rem] text-blue font-extrabold pl-4'>PORTFOLIO BUILDING</h3>
				</div>
				<div id='about-see-more' className='mt-10'>
					<a href="/about" className='abutton px-[20px] py-[8px] bg-blue rounded-full'>see more</a>
				</div>
			</div>
			<div id="website-about-right" className="relative w-full h-full">
				<div id="website-about-right-container" className="card w-full h-[25rem] rounded-[10px] p-6">
					<div className="flex justify-between">
						<p className="text-[var(--soft-text)]">Projects</p>
						<p className="cursor-pointer hover:text-yellow" onClick={() => window.location.replace('https://app.worklabspace.com')}>All Projects</p>
					</div>
					<div className="w-full h-[1px] bg-[grey] my-4">
					</div>
					<div id="website-about-jobs-slider" className="w-full h-[25rem] flex flex-col gap-4">
						<ProjectCard role={"Software Engineer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Java"} method={"Remote"} deadline={"25-11-2023"} />
						<ProjectCard role={"Data Analyst"} type={"Training"} contract={"Full-time"} company={"Vision D"} tech={"Python"} method={"On-site"} deadline={"15-01-2024"} />
						<ProjectCard role={"Frontend Developer"} type={"Vacancy"} contract={"Freelance"} company={"Vision D"} tech={"JavaScript"} method={"Remote"} deadline={"30-03-2024"} />
						<ProjectCard role={"DevOps Engineer"} type={"Training"} contract={"Contract"} company={"Vision D"} tech={"AWS"} method={"Remote"} deadline={"10-05-2024"} />
						<ProjectCard role={"UI/UX Designer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Figma"} method={"Hybrid"} deadline={"07-07-2024"} />
						<ProjectCard role={"Backend Developer"} type={"Vacancy"} contract={"Temporary"} company={"Vision D"} tech={"Node.js"} method={"Remote"} deadline={"18-09-2024"} />

						<ProjectCard role={"Software Engineer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Java"} method={"Remote"} deadline={"25-11-2023"} />
						<ProjectCard role={"Data Analyst"} type={"Training"} contract={"Full-time"} company={"Vision D"} tech={"Python"} method={"On-site"} deadline={"15-01-2024"} />
						<ProjectCard role={"Frontend Developer"} type={"Vacancy"} contract={"Freelance"} company={"Vision D"} tech={"JavaScript"} method={"Remote"} deadline={"30-03-2024"} />
						<ProjectCard role={"DevOps Engineer"} type={"Training"} contract={"Contract"} company={"Vision D"} tech={"AWS"} method={"Remote"} deadline={"10-05-2024"} />
						<ProjectCard role={"UI/UX Designer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Figma"} method={"Hybrid"} deadline={"07-07-2024"} />
						<ProjectCard role={"Backend Developer"} type={"Vacancy"} contract={"Temporary"} company={"Vision D"} tech={"Node.js"} method={"Remote"} deadline={"18-09-2024"} />
					</div>
				</div>
			</div>
		</div>
	);
}