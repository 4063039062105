import React from 'react';

import Footer from "../../components/Footer/footer";

function NotFoundPage() {
    return (
        <>
            <div id='NotFoundPage' className='relative w-full h-screen flex flex-col justify-center items-center'>
                <p onClick={() => window.history.back()} className='fixed top-8 left-8 cursor-pointer'><span className='text-yellow'>←</span> &ensp; <span >Go back</span></p>
                <h1 className='text-h1 font-bold'>404 Error</h1>
                <p className='text-p'>Page not found</p>
            </div>
            <Footer />
        </>
    )
}

export default NotFoundPage;