import React from 'react';

import { Project, Eval, Course, Skill } from './utils'

const Agent: React.FC = () => {

    const [section, setSection] = React.useState<number>(1);

    // const [currentCardIndex, setCurrentCardIndex] = useState(0);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     setCurrentCardIndex(prevIndex => (prevIndex + 1) % 2); // Assuming you have 2 cards. Adjust as needed.
    //   }, 7000);
  
    //   return () => clearInterval(interval); // Cleanup on component unmount
    // }, []);

    const Sections = ({section}:{section:number}) => {

        const sectionAgent = [
            <>
                <div className={`w-full min-h-full flex flex-col justify-center items-center  gap-6`}>
                    <Project role={"Software Engineer"} type={"Vacancy"} contract={"Part-time"} company={"Vision D"} tech={"Java"} method={"Remote"} deadline={"25-11-2023"} />
                    <Project role={"Data Analyst"} type={"Training"} contract={"Full-time"} company={"Vision D"} tech={"Python"} method={"On-site"} deadline={"15-01-2024"} />
                </div>
                {/* <div className={`hiw-section-one invert-card w-full min-h-full p-8 flex flex-col gap-6`}>
                    <h3 className="text-[2rem] text-yellow">Join Us</h3>
                    <div className="flex gap-8">
                        <input type="text" className="w-full bg-[transparent] outline-none p-2 border-b-2 border-[var(--soft-text)]" placeholder="First Name" />
                        <input type="text" className="w-full bg-[transparent] outline-none p-2 border-b-2 border-[var(--soft-text)]" placeholder="Last Name" />
                    </div>
                    <div className="flex gap-8">
                        <input type="email" className="w-full bg-[transparent] outline-none p-2 border-b-2 border-[var(--soft-text)]" placeholder="Email" />
                        <input type="password" className="w-full bg-[transparent] outline-none p-2 border-b-2 border-[var(--soft-text)]" placeholder="Password" />
                    </div>
                    <div>
                        <button className="px-4 h-[2.5rem] text-center bg-[transparent] rounded-full text-white border-[1] border-white transition-all duration-300 hover:bg-white hover:text-black">Get started</button>
                    </div>
                </div> */}
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>01</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Sign Up & Explore</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Register on Worklab and set up your profile, highlighting your skills and experiences.</li>
                        <li className='list-disc'>Browse through the available projects, both Vacancies and Training, tailored to your interests and expertise.</li>
                    </ul>
                </div>
            </>,
            <>
                <div className={`w-full min-h-full flex flex-col justify-center items-center  gap-6`}>
                    <Course about="Learn the basics of AI and machine learning in 90 minutes." company="Vision D" participants={24}/>
                </div>
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>02</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Practice & Learn</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Choose a project that aligns with your goals. Subscribe and start working on your project submission.</li>
                        <li className='list-disc'>Use the platform's resources, such as courses, to enhance your knowledge and deliver quality work.</li>
                    </ul>
                </div>
            </>,
            <>
                <div className='invert-card w-full min-h-full p-6 flex flex-col'>
                    <h3 className='text-left mb-8 text-yellow'>Evaluate</h3>
                    <Eval name="Android App" user="James Smith" deadline={1} />
                    <Eval name="Landing Page" user="Joe McAffey" deadline={2} />
                </div>
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>03</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Peer Evaluation</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>After project submission, participate in the peer review process, evaluating other agents' projects and receiving feedback on yours.</li>
                        <li className='list-disc'>Gain insights from diverse perspectives and understand areas of improvement.</li>
                    </ul>
                </div>
            </>,
            <>
                <div id='hiw-section-four' className='invert-card w-full min-h-full p-8 flex flex-col'>
                    <h3 className='mb-8'>Skills</h3>
                    <Skill skill="Design" knowledge='40' />
                    <Skill skill="Front-End" knowledge='80' />
                    <Skill skill="Data Analyst" knowledge='50' />
                    <Skill skill="Financial Manager" knowledge='0' />
                    <Skill skill="Back-end" knowledge='20' />
                </div>
                <div className='w-full min-h-full p-8 flex flex-col gap-6'>
                    <h2 className='font-bold text-green tracking-[1px]'>04</h2>
                    <h3 className='howItWorks-section-title text-[2rem]'>Skills & Portfolio</h3>
                    <ul className='howItWorks-section-list pl-4 flex flex-col gap-4'>
                        <li className='list-disc'>Based on evaluations, receive a grade for your project. For Vacancy projects, stand a chance to work with top companies.</li>
                        <li className='list-disc'>Watch your skills bar and portfolio grow as you complete projects, and unlock achievements to boost your professional journey.</li>
                    </ul>
                </div>
            </>
        ];

        return sectionAgent[section - 1];
    }

    return (
        <div className='w-full h-full text-left'>
            <div className='howItWorks-card card flex w-full min-h-[25rem] p-8'>
                <Sections section={section}/>
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mt-8">
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 1 ? "border-green" : "border-grey"}`} onClick={() => setSection(1)}>
                    <p className='text-green'>01</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Sign Up & Explore</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 2 ? "border-green" : "border-grey"}`} onClick={() => setSection(2)}>
                    <p className='text-green'>02</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Practice & Learn</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 3 ? "border-green" : "border-grey"}`} onClick={() => setSection(3)}>
                    <p className='text-green'>03</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Peer Evaluation</p>
                </div>
                <div className={`py-6 cursor-pointer border-t-[4px] flex flex-col gap-4 ${section === 4 ? "border-green" : "border-grey"}`} onClick={() => setSection(4)}>
                    <p className='text-green'>04</p>
                    <p className='howItWorks-choose-title text-[1.5rem]'>Skills & Portfolio</p>
                </div>
            </div>

        </div>
    );
};

export default Agent;
