import React from 'react';

import './terms.css';
import TermsData from './data.json';
import Footer from '../../components/Footer/footer';

export default function Terms() {
    return (
        <>
            <div id='terms' className='relative w-full flex flex-col px-[15rem] text-left py-[5rem]'>
                <p onClick={() => window.history.back()} className='absolute w-full top-0 left-0 py-5 px-5 text-left cursor-pointer z-[5]'><span className='text-yellow'>←</span> &ensp; <span>Go back</span></p>
                <h1 className='text-h2'>Worklab <br /> Terms & Conditions</h1>
                <p className='text-p pb-16'>Last Updated: 01/10</p>
                <div className='relative flex flex-col gap-10'>
                    {
                        TermsData.data.map((section) => {
                            return (
                                <div key={section.id} className='relative w-full flex flex-col gap-5'>
                                    <h2 className='text-[var(--yellow)] text-h3'>
                                        {section.title}
                                    </h2>
                                    <p>
                                        {section.text}
                                    </p>
                                </div>
                            )
                        })
                    }
                    <div className='relative w-full flex flex-col gap-5'>
                        <h2 className='text-[var(--yellow)] text-h3'>
                            Privacy
                        </h2>
                        <p>
                            Users' privacy is of utmost importance. Please refer to our <a href="/privacy-policy" className='font-bold hover:underline'>Privacy Policy</a> to understand how we collect, use, and share your personal data.
                        </p>
                    </div>
                    <div className='relative w-full flex flex-col gap-5'>
                        <h2 className='text-[var(--yellow)] text-h3'>
                            Contact us
                        </h2>
                        <p>
                            If you have any questions about these terms, please contact us <a href="/support" className='font-bold hover:underline'>here</a>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}